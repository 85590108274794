import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import ArticlesComponent from "../components/articles"

import "../assets/css/main.css"

const IndexPage = () => {

  return (
    <Layout>
      <StaticQuery
        query={graphql`
          query {
            allSupabaseProject {
              nodes {
                title
                description
                image
                url
                id
              }
            }
          }
        `}
        render={data => (
          <section>
            <ArticlesComponent articles={data.allSupabaseProject.nodes} />
          </section>
        )}
      />
    </Layout>
  );
}

export default IndexPage