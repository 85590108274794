import React from "react"
import styled from "styled-components"

const CardItem = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 6rem;
  opacity: 0;
  transform: translate(0, 10%);
  visibility: hidden;
  transition: opacity 600ms ease-out, transform 600ms ease-out;
  will-change: opacity, visibility;
  &.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
    display: flex; 
  }
  .image {
    overflow: hidden;
    border-radius: 4px;
    display: grid;
  }
  img {
    width: 100%;
    opacity: 0.7;
    transition: all 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
    display: grid;
    object-fit: contain;
  }
  h3 {
    color: #090c39;
    font-weight: 500;
    font-size: 1.2rem;
  }
  p {
    color: #000;
    &.stack {
      line-height: 1.6;
      font-size: 1rem;
    }
  }
  &:hover {
    img {
      opacity: 1;
      transform: scale(1.02);
    }
    .link:after { 
      width: 100%; 
      left: 0; 
    }
  }
`

const Link = styled.div`
  position: absolute;
  color: #090c39;
  padding-bottom: 5px;
  font-weight: 500;
  &:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #090c39;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
`

const Card = ({ article }) => {

  const domRef = React.useRef();
  
  const [isVisible, setVisible] = React.useState(false);

  React.useEffect(() => {

    const currentCard = domRef.current

    const observer = new IntersectionObserver(entries => {
      // In your case there's only one element to observe:     
      if (entries[0].isIntersecting) {
      
        // Not possible to set it back to false like this:
        setVisible(true);
        
        // No need to keep observing:
        observer.unobserve(currentCard);
      }
    });
    
    observer.observe(currentCard);
    
    return () => observer.unobserve(currentCard);
  }, []);

  return (
    <a aria-label={article.title} href={article.url} target="_blank" rel="noreferrer">
      <CardItem ref={ domRef } className={ isVisible ? ' is-visible' : '' }>
        {article.image && <div className="image">
          <img
            src={article.image}
            alt={article.image}
          />
        </div>}
        <div className="content">
          <h3 className="title">
            {article.title}
          </h3>
          <p className="stack">{ article.description }</p>
          <Link className="link">View work</Link>
        </div>
      </CardItem>
    </a>
  )
}

export default Card