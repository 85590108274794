import React from "react"
import styled from "styled-components"
import Card from "./card"

const ArticleWrap = styled.section`
  display: flex;
  margin: 0 auto;
  max-width: 800px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  content-visibility: auto;
  contain-intrinsic-size: 3500px;
`

const Articles = ({ articles }) => {

  return (
    <ArticleWrap>
      {articles.map((article, i) => {
        return (
          <Card article={article} key={`article__${i}`} />
        )
      })}
    </ArticleWrap>
  )
}

export default Articles